import React from "react"
import { css } from "@emotion/core"
import HotspotIcon from "./hotspotIcon"
import { breakpoint } from "./../../constants"
import { useViewport } from "./../../contexts/viewContext"
import PropTypes from "prop-types"

const root = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  cursor: pointer;
  outline: none;
  padding-bottom: 0.1rem;
  @media (max-width: ${breakpoint}px) {
    background-color: #fff;
    padding: 0 0.8rem 0.4rem 0.8rem;
  }
`

const hotspotName = css`
  font-size: 0.12rem;
  text-transform: uppercase;
  width: 1.6rem;
  padding: 0;
  margin: 0;
  margin-left: 0.1rem;
  @media (max-width: ${breakpoint}px) {
    font-size: 0.26rem;
    width: 4rem;
    margin-left: 0.2rem;
  }
`

const iconWrapper = css`
  width: 0.5rem;
  @media (max-width: ${breakpoint}px) {
    width: 1.4rem;
  }
`

const Hotspot = ({ slug, i, title, handleClick }) => {
  const { width } = useViewport()

  return (
    <div
      role="button"
      css={root}
      tabIndex={i + 1}
      onKeyDown={() => handleClick(slug)}
      onClick={() => handleClick(slug)}
    >
      <div css={iconWrapper}>
        <HotspotIcon slug={slug} i={i} bigFont={width <= breakpoint} />
      </div>
      <h6 css={hotspotName}>{title}</h6>
    </div>
  )
}

Hotspot.propTypes = {
  slug: PropTypes.string.isRequired,
  i: PropTypes.number,
  title: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
}

export default Hotspot
