import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import { breakpoint } from "./../../constants"
import AudioIcon from "../audioIcon"

const root = css`
  width: 100%;
  height: auto;
  background-color: #819cd1;
  position: relative;
  display: flex;
  align-items: center;
  overflow: visible;
  min-height: 0.8rem;
  @media (max-width: ${breakpoint}px) {
    height: auto;
    min-height: 0;
    flex-direction: column;
  }
`
const desc = css`
  width: 36%;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-left: 3rem;
  padding: 0;
  font-size: 0.7em;
  color: #fff;
  font-family: "LoraItalic", serif;
  @media (max-width: ${breakpoint}px) {
    font-size: 3em;
    padding: 0.6rem;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
`

const sign = css`
  margin: 0;
  padding: 0;
  position: absolute;
  font-size: 6em;
  top: -0.45rem;
  left: 3rem;
  color: rgba(0, 0, 0, 0.4);
  font-family: "LoraItalic", serif;
  @media (max-width: ${breakpoint}px) {
    font-size: 12em;
    top: -0.8rem;
    left: 1rem;
  }
`

const icon = css`
  position: absolute;
  width: 0.7rem;
  top: -0.2rem;
  right: 0.6rem;
  @media (max-width: ${breakpoint}px) {
    position: static;
    width: 40%;
    margin-bottom: 0.8rem;
  }
`

const Mid = React.memo(({ audioCaption, audioClip, audioFace }) => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(filter: { extension: { eq: "mp3" } }) {
          edges {
            ...AudioURL
          }
        }
      }
    `
  )

  const node = allFile.edges.find(n => {
    return n.node.name === audioClip
  })

  let url = ""
  if (node) {
    url = node.node.publicURL
  } else {
    console.log(`${audioClip} can not been found!`)
  }

  return (
    <div css={root}>
      {audioCaption && <p css={desc}>{audioCaption}</p>}
      {audioCaption && <p css={sign}>“</p>}
      {audioClip && (
        <div css={icon}>
          <AudioIcon url={url} audioFace={audioFace} />
        </div>
      )}{" "}
    </div>
  )
})

export default Mid
