import React from "react"
import { css } from "@emotion/core"
import Top from "./top"
import Mid from "./mid"
import Bottom from "./bottom"
import { breakpoint } from "./../../constants"
import PropTypes from "prop-types"
import SecondImg from "./secondImg"
const root = css`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: transparent;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  z-index: 100;
`
const container = css`
  position: absolute;
  left: 5vw;
  top: 10vh;
  width: 90vw;
  z-index: 200;
  background-color: rgba(255, 255, 255, 0.96);
  font-size: 0.2rem;
  overflow-y: auto;
  max-height: 80vh;
  outline: none;
`

const close = css`
  top: 12vh;
  right: 7vw;

  width: 0.2rem;
  height: 0.2rem;
  border: 4px solid rgba(0,0,0,0.8);
  border-radius: 50%;
  position: fixed;
  cursor: pointer;
  outline: none;
  z-index: 500;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 60%;
    height: 4px;
    background: rgba(0,0,0,0.8);
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 44%;
    left: 20%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 60%;
    height: 4px;
    background: rgba(0,0,0,0.8);
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    top: 44%;
    left: 20%;
  }
  @media (max-width: ${breakpoint}px) {
    width: 0.6rem;
    height: 0.6rem;
    border: 2px solid rgba(0,0,0,0.8);
    &:before {
      height: 2px;
    }
    &:after {
      height: 2px;
    }
  }
`

const HotSpotPopup = ({ info, onClose }) => {
  const handleClick = e => {
    e.stopPropagation()
  }

  return (
    <div
      css={root}
      role="button"
      tabIndex={0}
      onKeyDown={onClose}
      onClick={onClose}
    >
      <div
        role="button"
        tabIndex={0}
        onKeyDown={handleClick}
        onClick={handleClick}
        css={container}
      >
        <div
          css={close}
          role="button"
          tabIndex={0}
          onClick={onClose}
          onKeyDown={onClose}
        >
          {" "}
        </div>
        <Top
          headerImage={info.headerImage}
          title={info.title}
          subTitle={info.subTitle}
          desc={info.desc}
        />
        <Mid audioCaption={info.audioCaption} audioClip={info.audioClip} audioFace={info.audioFace} />
        {info.secondaryImage && (
          <SecondImg
            img={info.secondaryImage}
            text={info.secondaryImageCaption}
          />
        )}
        {info.video && <Bottom video={info.video} />}
      </div>
    </div>
  )
}

HotSpotPopup.propTypes = {
  info: PropTypes.object,
  onClose: PropTypes.func,
}

export default HotSpotPopup
