import React from "react"
import { css } from "@emotion/core"
import Hotspot from "./hotspot"
import { breakpoint } from "./../../constants"
import Image from "./../image"
import PropTypes from "prop-types"

const content = css`
  padding: 0.1rem 1rem 1rem 1.4rem;
  background-color: #f1ead8;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${breakpoint}px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    padding: 0;
  }
`

const col = css`
  width: 45%;
  @media (max-width: ${breakpoint}px) {
    width: 100%;
  }
`

const map = css`
  width: 100%;
  margin-bottom: 0.1rem;
`

const floor = css`
  width: 30%;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.2rem;
  text-align: center;
  @media (max-width: ${breakpoint}px) {
    margin: 0.2rem auto;
    font-size: 0.6rem;
  }
`
const floorTitleCss = css`
  font-size: 0.6em;
`

const floorNumCss = css`
  font-size: 1em;
  margin-left: 0.08rem;
  @media (max-width: ${breakpoint}px) {
    margin-left: 0.2rem;
  }
`

const roomTitle = css`
  text-transform: uppercase;
  font-size: 0.22rem;
  margin: 0;
  padding-bottom: 0.1rem;
  @media (max-width: ${breakpoint}px) {
    background-color: #fff;
    font-size: 0.42rem;
    padding: 0.4rem 0.8rem;
  }
`


// match the files' name of maps
// TODO: Servant’s Room map is not right now
const mapNameMatches = {
  "library": "Maps - Floor 1/LIBRARY.png",
  "drawing room": "Maps - Floor 1/DRAWINGROOM.png",
  "dining room": "Maps - Floor 1/DINNINGROOM.png",
  "smoking room": "Maps - Floor 1/SMOKING ROOM.png",
  "breakfast room": "Maps - Floor 1/BREAKFAST ROOM.png",
  "kitchen": "Maps - Floor 1/KITCHEN.png",
  "entrance hall": "Maps - Floor 1/ENTRANCE HALL.png",
  "main hall": "Maps - Floor 1/MAIN HALL.png",
  "mrs. dunsmuir's sitting room": "Maps - Floor 2/MRS D SITTING ROOM.png",
  "hall a": "Maps - Floor 2/HALL A.png",
  "maud's bedroom": "Maps - Floor 2/MAUDS ROOM.png",
  "billiard room": "Maps - Floor 3/BILLIARD ROOM.png",
  "robert harvey's bedroom": "Maps - Floor 3/RH BEDROOM.png",
  "hall b": "Maps - Floor 3/HALL B.png",
  "servant's room": "Maps - Floor 3/SERVANT ROOM.png",
  "effie's bedroom": "Maps - Floor 3/ELLIES BEDROOM.png",
  "dance hall": "Maps - Floor 4/DANCE HALL.png",
  "the tower": "Maps - Floor 4/THE TOWER.png",
}

const RoomInfo = ({ roomName, floorNum, hotspots, handleClick }) => {
  return (
    <div css={content}>
      <div css={col}>
        <div css={map}>
          <Image filename={mapNameMatches[roomName]} />
        </div>
        <div css={floor}>
          <span css={floorTitleCss}>floor</span>
          <span css={floorNumCss}>{floorNum}</span>
        </div>
      </div>
      <div css={col}>
        <h6 css={roomTitle}>{roomName}</h6>
        {hotspots.map((n, i) => (
          <Hotspot
            key={i}
            slug={n.node.slug}
            i={i + 1}
            title={n.node.title}
            handleClick={handleClick}
          />
        ))}
      </div>
    </div>
  )
}

RoomInfo.propTypes = {
  roomName: PropTypes.string.isRequired,
  floorNum: PropTypes.string,
  hotspot: PropTypes.array,
  handleClick: PropTypes.func,
}

export default RoomInfo
