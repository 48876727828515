/**
 * render a video
 */

import React from "react"
import { css } from "@emotion/core"
import { breakpoint } from "./../constants"
import PropTypes from "prop-types"
import Player from "@vimeo/player"

const showVideo = css`
  width: 4rem;
  height: 2rem;

  @media (max-width: ${breakpoint}px) {
    width: 100%;
    height: 100%;
  }
`
const Video = ({ videoSrcURL }) => {
  const vimeoPlayer = React.useRef()
  React.useEffect(() => {
    if (vimeoPlayer && vimeoPlayer.current) {
      let player = new Player(vimeoPlayer.current);
      player.setLoop(true) // set loop play
    } 
  }, [vimeoPlayer]);
  return (
    <iframe
      ref={vimeoPlayer}
      css={showVideo}
      title="video"
      src={videoSrcURL}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  )
}

Video.prototype = {
  videoSrcURL: PropTypes.string.isRequired
}

export default Video
