/**
 * This component render a oblique border
 */

import { css } from "@emotion/core"
import React from "react"
import { breakpoint } from "./../constants"
import { useViewport } from "./../contexts/viewContext"
import PropTypes from "prop-types"

const border = css`
  width: 0;
  height: 0;
  border-left: 100vw solid transparent;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 20;
`
const Border = ({ color, display }) => {
  const { width } = useViewport()
  let condition
  display === "desktop"
    ? (condition = width > breakpoint)
    : (condition = width <= breakpoint)
  return (
    <>
      {condition ? (
        <div
          css={css`
            ${border};
            border-bottom: 0.5rem solid ${color};
          `}
        />
      ) : null}
    </>
  )
}

Border.propTypes = {
  color: PropTypes.string,
  display: PropTypes.string,
}

export default Border
