import React from "react"
import { css } from "@emotion/core"
import Video from "../video"
import { breakpoint } from "../../constants"
import PropTypes from "prop-types"

const root = css`
  width: 100%;
  height: 2.4rem;
  margin-top: 0.1rem;
  text-align: center;
  @media (max-width: ${breakpoint}px) {
    width: 100%;
    height: 4rem;
  }
`

const videoUrl = {
  sewingkit: "https://player.vimeo.com/video/429463665",
  watertank: "https://player.vimeo.com/video/429463974",
  pantrydoor: "https://player.vimeo.com/video/429464017",
}

const Bottom = ({ video }) => {
  const url = videoUrl[video]
  return (
    <div css={root}>
      <Video videoSrcURL={url} />
    </div>
  )
}

Bottom.propTypes = {
  video: PropTypes.string
}

export default Bottom
