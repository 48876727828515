import React from "react"
import { css } from "@emotion/core"
import Image from "../image"
import { breakpoint } from "./../../constants"
import PropTypes from "prop-types"

const root = css`
  width: 100%;
  height: 2.6rem;
  display: flex;
  box-sizing: border-box;
  @media (max-width: ${breakpoint}px) {
    flex-direction: column-reverse;
    height: auto;
  }
`

const cover = css`
  width: 36%;
  margin-left: 0.2rem;
  
  box-sizing: border-box;
  overflow: visible;
  z-index: 30;
  @media (max-width: ${breakpoint}px) {
    width: 100%;
    margin: 0;
    padding: 0.4rem;
  }
`

const info = css`
  display: flex;
  width: 40%;
  flex-direction: column;
  margin-top: 0.4rem;
  margin-left: 10%;
  h2 {
    margin: 0;
    font-size: 1em;
    text-transform: uppercase;
    color: #819cd1;
  }

  h4 {
    margin: 0;
    font-size: 0.6em;
    font-family: "LoraItalic", serif;
    color: #fbb54e;
    font-weight: normal;
  }

  p {
    margin: 0;
    font-size: 0.5em;
    margin-top: 0.1rem;
  }
  @media (max-width: ${breakpoint}px) {
    flex-direction: column;
    width: 100%;
    margin-top: 0.6rem;
    padding: 0 0.4rem;
    box-sizing: border-box;
    margin-left: 0;
    h2 {
      font-size: 3.2em;
    }
    h4 {
      font-size: 3em;
    }
    p {
      font-size: 2.2em;
    }
  }
`

const Top = ({ headerImage, title, subTitle, desc }) => (
  <div css={root}>
    <div css={cover}>
      <Image filename={`artifacts/${headerImage}.png`} />
    </div>
    <div css={info}>
      <h2>{title}</h2>
      <h4>{subTitle}</h4>
      <p>{desc}</p>
    </div>
  </div>
)

Top.propTypes = {
  headerImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired
}

export default Top
