import React from "react"
import { css } from "@emotion/core"
import { breakpoint } from "../../constants"
import Image from "../image"
import { useViewport } from "../../contexts/viewContext"
import PropTypes from "prop-types"

const root = css`
  width: 100%;
  height: auto;
`

const mobileRoot = css`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  overflow: hidden;
`

const background = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -10;
`

const imgWrapper = css`
  width: 90%;
  margin: 0.4rem;
  box-sizing: border-box;
`
const textMobile = css`
  font-family: "LoraRegular";
  font-size: 0.4rem;
  margin: 0.4rem;
  padding: 0;
  width: 80%;
`
const SecondImgMobile = ({img, text}) => (
  <div css={mobileRoot} >
    <div css={background} >
      <Image filename="assets/tour/mobile/paperbg.png" />
    </div>
    <div css={imgWrapper}>
      <Image filename={`secondaryImageMobile/${img}_mobile.png`} />
    </div>
    {
      text && <p css={textMobile}>{text}</p>
    }
  </div>
)

const SecondImg = ({img, text}) => {
  const { width } = useViewport()
  return (
    <div css={root}>
      {
        width > breakpoint
        ? <Image filename={`artifacts/${img}.png`} />
        : <SecondImgMobile img={img} text={text} />
      }
      
    </div>
  )
}

SecondImg.propTypes = {
  img: PropTypes.string.isRequired,
  text: PropTypes.string,
}
export default SecondImg

