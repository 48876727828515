import React from "react"
import { css } from "@emotion/core"
import Image from "../image"
import { breakpoint } from "./../../constants"
import PropTypes from "prop-types"

const index = css`
  position: absolute;
  bottom: 17%;
  left: 60%;
  color: #fff;
  z-index: 5;
`

const bigger = css`
  font-size: 0.32rem;
`
const size = css`
  font-size: 0.12rem;
  @media (max-width: ${breakpoint}px) {
    font-size: 0.2rem;
  }
`

const img = css`
  width: 100%;
  position: relative;
  margin-right: 0.1rem;
  transform: translateY(-10%);
`

const hotspotTypeMatches = {
  castle: "assets/room/hotspot-castle.png",
  gender: "assets/room/hotspot-gender.png",
  class: "assets/room/hotspot-class.png",
}

const HotspotIcon = ({ slug, i, bigFont }) => {
  return (
    <div css={img}>
      <Image filename={hotspotTypeMatches[slug.split("_")[0]]} />
      {bigFont ? (
        <div
          css={css`
            ${index};
            ${bigger}
          `}
        >
          {i}
        </div>
      ) : (
        <div
          css={css`
            ${index};
            ${size}
          `}
        >
          {i}
        </div>
      )}
    </div>
  )
}

HotspotIcon.propTypes = {
  slug: PropTypes.string.isRequired,
  i: PropTypes.number,
  bigFont: PropTypes.bool,
}

export default HotspotIcon
