import React from "react"
import Layout from "./../components/layout"
import SEO from "./../components/seo"
import { css } from "@emotion/core"
import Image from "./../components/image"
import RoomInfo from "../components/room/roomInfo"
import HotSpotPopup from "../components/hotspotOverlay/hotspotPopup"
import HotspotIcon from "../components/room/hotspotIcon"
import { breakpoint } from "./../constants"
import Border from "../components/border"
import { graphql } from "gatsby"

const root = css`
  position: relative;
  background-color: #f1ead8;
  padding: .3rem 0;
  @media (max-width: ${breakpoint}px) {
    padding: 1rem 0;
    min-height: 100vh;
  }
`

const cover = css`
  width: 100vw;
  height: 3.8rem;
  position: relative;
  overflow: hidden;
`

const icon = css`
  position: absolute;
  width: 0.5rem;
  z-index: 15;
  transform: translate(-50%, -50%);
  cursor: pointer;
  outline: none;
  @media (max-width: ${breakpoint}px) {
    width: 0.9rem;
  }
`
// load all the hotspot' information who's room match this page from json files
export const query = graphql`
  query($slug: String!) {
    allDataJson(filter: { room: { eq: $slug } }) {
      edges {
        node {
          slug
          title
          subtitle
          desc
          audio_caption
          audio_clip
          header_image
          video
          location_x
          location_y
          secondary_image
          secondary_image_caption
          audio_face
        }
      }
    }
  }
`

const Room = props => {
  const [showTour, setShowTour] = React.useState(false)
  const [showPopup, setShowPopup] = React.useState(false)

  const locations = props.data.allDataJson.edges.map(n => {
    let ratioX = n.node.location_x / 30.0 + "%"
    let ratioY = n.node.location_y / 15.37 + "%"
    return {
      ratioX,
      ratioY,
      slug: n.node.slug,
    }
  })


  const [hotspotInfo, setHotspotInfo] = React.useState({
    title: "",
    subTitle: "",
    desc: "",
    audioCaption: "",
    audioClip: "",
    headerImage: "",
    video: "",
    secondaryImage: "",
    secondaryImageCaption: "",
    audioFace: "",
  })

  let floorNum =
    props.pageContext.floor.charAt(props.pageContext.floor.length - 1)

  const handleClick = slug => {
    const hotspotNode = props.data.allDataJson.edges.find(n => {
      return n.node.slug === slug
    })
    const info = {
      title: hotspotNode.node.title,
      subTitle: hotspotNode.node.subtitle,
      desc: hotspotNode.node.desc,
      audioCaption: hotspotNode.node.audio_caption,
      audioClip: hotspotNode.node.audio_clip,
      headerImage: hotspotNode.node.header_image,
      video: hotspotNode.node.video,
      secondaryImage: hotspotNode.node.secondary_image,
      secondaryImageCaption: hotspotNode.node.secondary_image_caption,
      audioFace: hotspotNode.node.audio_face,
    }
    setHotspotInfo(info)
    setShowPopup(true)
    document.body.scroll = "no"
    document.body.style.overflow = "hidden"
  }
  const handleClose = () => {
    setShowPopup(false)
    document.body.scroll = "yes"
    document.body.style.overflowY = "auto"
    document.body.style.overflowX = "hidden"
  }
  return (
    <Layout
      showTour={showTour}
      toggleTour={() => setShowTour(showTour => !showTour)}
    >
      <SEO title={props.pageContext.roomName.toUpperCase()} />
      <div css={root}>
        <div css={cover}>
          <Image filename={`Rooms/${props.pageContext.slug}.jpg`} />
          {locations.map((pos, i) => {
            return (
              <div
                key={i}
                css={css`
                  ${icon};
                  top: ${pos.ratioY};
                  left: ${pos.ratioX};
                `}
                onClick={() => handleClick(pos.slug)}
                onKeyDown={() => handleClick(pos.slug)}
                role="button"
                tabIndex={i + 1}
              >
                <HotspotIcon slug={pos.slug} i={i + 1} />
              </div>
            )
          })}
          <Border color="#f1ead8" display="desktop" />
        </div>
        <RoomInfo
          roomName={props.pageContext.roomName}
          floorNum={floorNum}
          hotspots={props.data.allDataJson.edges}
          handleClick={handleClick}
        />
        {showPopup && (
          <HotSpotPopup
            info={hotspotInfo}
            onClose={handleClose}
          />
        )}
      </div>
    </Layout>
  )
}

export default Room
